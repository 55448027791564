import moment from "moment";

export class contract{
    /** @param obj {contract} */
    constructor(obj){
        /** @type string */
        this.id = obj.id;

        /** @type string */
        this.factionSymbol = obj.factionSymbol;

        /** @type string */
        this.type = obj.type;

        /** @type term[] */
        this.terms = obj.terms;

        /** @type boolean */
        this.accepted = obj.accepted;

        /** @type boolean */
        this.fulfilled = obj.fulfilled;

        /** @type {Date|string} */
        this.expiration = obj.expiration;

        /** @type {Date|string} */
        this.deadlineToAccept = obj.deadlineToAccept;
    }

    toString(){
        console.log(this);
        //moment.locale('fr');
        moment.locale('en');
        return `Id: ${this.id} \nType: ${this.type} \nStatus: ${this.accepted ? "Accepted" : "Pending"}, ${this.fulfilled ? "fulfilled" : "not yet fulfilled"} \nExpires in: ` + moment(new Date(this.expiration.toString())).fromNow(true);
    }
}

export class term{
    constructor(obj){
        /** @type {Date|string} */
        this.deadline = obj.deadline;

        /** @type payment */
        this.payment = obj.payment;

        /** @type deliver[] */
        this.deliver = obj.deliver;
    }
}

export class payment{
    constructor(obj){
        /** @type number */
        this.onAccepted = obj.onAccepted;

        /** @type number */
        this.onFulfilled = obj.onFulfilled;
    }
}

export class deliver{
    constructor(obj){
        /** @type string */
        this.tradeSymbol = obj.tradeSymbol;

        /** @type string */
        this.destinationSymbol = obj.destinationSymbol;

        /** @type number */
        this.unitsRequired = obj.unitsRequired;

        /** @type number */
        this.unitsFulfilled = obj.unitsFulfilled;
    }
}