<template>
  <button @click="serverStatus">Status</button>
  <br><br>
  <GG text="text_prop_custom_value"></GG>
</template>

<script>
import { status } from "./libs/api/models/status.js";
import GG from "./components/GG.vue";

export default {
  name: 'App',
  components: { GG },
  data() {
    return {};
  },
  methods: {
    serverStatus() {
      fetch('https://api.spacetraders.io/v2/')
        .then((response) => response.json())
        .then((response) => console.log(new status(response)))
        .catch((err) => console.error(err));
    },
  },
};
</script>

<style></style>
