export class error{
    constructor(obj){
        this.message = obj.message;
        this.code = obj.code ?? obj.status;
        this.data = new data(obj.data);
    }
}

export class data{
    constructor(obj){
        if(obj){
            this.symbol = obj.symbol;
            this.faction = obj.faction;
            this.agentSymbol = obj.agentSymbol;
        }
    }
}