export class status {
    constructor(obj) {
        this.status = obj.status;
        this.version = obj.version;
        this.resetDate = obj.resetDate;
        this.description = obj.description;
        this.stats = obj.stats;
        this.leaderboards = obj.leaderboards;
        this.serverResets = obj.serverResets;
        this.announcements = obj.announcements;
        this.links = obj.links;
    }
}

export class announcement {
    constructor(title, body) {
        this.title = title;
        this.body = body;
    }
}

export class leaderboards {
    constructor(mostCredits, mostSubmittedCharts) {
        this.mostCredits = mostCredits;
        this.mostSubmittedCharts = mostSubmittedCharts;
    }
}

export class link {
    constructor(name, url) {
        this.name = name;
        this.url = url;
    }
}

export class mostCredit {
    constructor(agentSymbol, credits) {
        this.agentSymbol = agentSymbol;
        this.credits = credits;
    }
}

export class mostSubmittedChart {
    constructor(agentSymbol, chartCount) {
        this.agentSymbol = agentSymbol;
        this.chartCount = chartCount;
    }
}

export class serverResets {
    constructor(next, frequency) {
        this.next = new Date(next);
        this.frequency = frequency;
    }
}

export class stats {
    constructor(agents, ships, systems, waypoints) {
        this.agents = agents;
        this.ships = ships;
        this.systems = systems;
        this.waypoints = waypoints;
    }
}