<template>
  <div class="GG">
    <button @click="register">Register</button>
    <br><br>
    <button @click="test">{{ text }}</button>
    <br><br>
    <button @click="getContracts">/v2/my/contracts</button>
    <br><br>
    <MoveableDiv style="display: none" id="myDiv" :options=options></MoveableDiv>
    <br><br>
  </div>
</template>

<script>
//import * as _api from "./libs/_api/ApiClient";
import * as api from "../libs/api/client.js";
import MoveableDiv, {options} from "@/components/MoveableDiv.vue";

export default {
  name: "GG",
  components: {MoveableDiv},
  props: {
    text: String,
  },
  // eslint-disable-next-line no-unused-vars
  setup(props) {
    // setup() receives props as the first argument.
    //console.log(props.text);
    document.body.style.background = "#321";
  },
  data(){
    return {
      options: (new options({resizable: true, draggable: true})),
    };
  },
  methods: {
    register() {
      console.log("Register");
      api.registerAgent("cdig_989_99ff");
    },
    test() {
      /*let _api_ = new _api.default();
      //_api_.
      console.log(_api_.buildUrl("test/{test1}", { "test1": "superTest" }));*/
    },
    getContracts() {
      api.getContracts(contracts => {
        for(let index in contracts)
        {
          console.log(`${contracts[index]}`);
        }
        //contracts.forEach(contract => console.log(contract));
      });
    },
  },
};
</script>

<style scoped>
.GG{
  background: dimgray;
  border: chocolate 3px solid;
  border-radius: 25px;
  padding: 30px;
}
</style>