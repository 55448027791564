export class registeringResponse {
    /** @param obj {registeringResponse} */
    constructor(obj) {
        /** @type string */
        this.token = obj.token;

        /** @type agent */
        this.agent = obj.agent;

        /** @type contract */
        this.contract = obj.contract;

        /** @type faction */
        this.faction = obj.faction;

        /** @type ship */
        this.ship = obj.ship;
    }
}