import SaveManager from "@/libs/saveManager";

class API {
    constructor(token) {
        this.setupAPI(token);
    }

    setupAPI(token) { this.token = token; }

    hasToken() { return !!this.token; }

    /**
     *
     * @param {string} endPoint
     * @param {object|array} params
     * @param {string} method
     * @param {function(APIResult):void} callback
     */
    queryApi(endPoint, params, method = "GET", callback) {
        const options = {
            method: method,
            headers: { "Content-Type": "application/json", },
            body: method !== "GET" ? JSON.stringify(params) : null,
        };

        if(this.hasToken())
            options.headers.Authorization = "Bearer " + this.token;

        let result = null;
        fetch("https://api.spacetraders.io/v2/" + endPoint, options)
            .then(async response => {
                if (!response.ok) throw new Error(`Response status: ${response.status}`);

                result = new APIResult({status: true, result: await response.json()});
            })
            .catch(async error => {
                console.log("_________________________________________________");
                console.log(Object.keys(await error));
                console.log(error.message);
                console.log("_________________________________________________");
                if (typeof error.json === "function") {
                    error.json().then(jsonError => {
                        console.log("Json error from API");
                        console.log(jsonError);
                        result = new APIResult({ status: false, result: jsonError });
                    }).catch(genericError => {
                        console.log("Generic error from API");
                        console.log(error.statusText);
                        console.log(genericError);
                        result = new APIResult({ status: false, result: error.statusText });
                    });
                } else {
                    console.log("Fetch error");
                    console.log(typeof error);
                    console.log(error.data);
                    result = new APIResult({ status: false, result: error });
                }
                //console.log(await err.json());
                //result = new APIResult({ status: false, result: await err.json() });
            })
            .finally(() => callback(result));
    }
}
export const instance = new API(SaveManager.read("auth_token"));


export class APIResult {
    constructor(obj) {
        /** @type {bool} */
        this.status = obj.status;
        /** @type {object} */
        this.result = obj.result;
    }
}