
export default class SaveManager{
    /**
     *
     * @param name {string}
     * @param value {object|array|any}
     * @param debug
     */
    static save(name, value, debug = false) {
        if(debug) console.log(name, value);
        localStorage.setItem(name, JSON.stringify(value));
    }

    /**
     * @param name {string}
     * @param debug Also log with console.log()
     * @returns {object|array|any}
     */
    static read(name, debug = false) {
        if(debug) console.log(localStorage.getItem(name));
        return JSON.parse(localStorage.getItem(name) ?? "null");
    }
}